export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/legal/ad-disclosure": [4,[2]],
		"/legal/credit-authorization-agreement": [5,[2]],
		"/legal/e-consent": [6,[2]],
		"/legal/how-it-works": [7,[2]],
		"/legal/privacy-policy": [8,[2]],
		"/legal/rates-and-fees": [9,[2]],
		"/legal/terms-and-conditions": [10,[2]],
		"/signup": [~11],
		"/signup/success": [12]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';